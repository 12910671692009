<template>
  <el-container class="container">
    <el-header height="auto">
      <h1>{{ $t('tutorialVideo.welcome') }}</h1>
      <div>{{ $t('tutorialVideo.subtitle') }}</div>
    </el-header>
    <el-container>
      <el-aside width="250px">
        <div class="video_box">
          <div class="title">{{ videos.basic.title }}</div>
          <div :class="['item',{active:currentVideo.link === bItem.link}]" v-for="(bItem,index) in videos.basic.list"
               :key="index" @click="videoClick(bItem)">{{
              bItem.label
            }}
          </div>
        </div>
        <div class="video_box">
          <div class="title">{{ videos.other.title }}</div>
          <div :class="['item',{active:currentVideo.link === oItem.link}]" v-for="(oItem,index) in videos.other.list"
               :key="index" @click="videoClick(oItem)">{{
              oItem.label
            }}
          </div>
        </div>

      </el-aside>
      <el-container>
        <el-main class="video_container" v-loading="frameLoading">
          <video :src="currentVideo.link" preload="metadata" :poster="currentVideo.poster" controls
                 v-if="$store.getters.lang === 'zh'"></video>
          <iframe ref="iframeRef" :src="currentVideo.link " v-else></iframe>
        </el-main>
        <el-footer height="106px">
          <span>{{ currentVideo.label }}</span>
          <img src="@/assets/logo_light.png" alt="" style="width: 40px;margin-left: 20px">
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
import index from './index.js'

export default index
</script>

<style scoped lang="scss">
@import './index.scss';
</style>