<template>
  <div class="guide-container">
    <!-- 场景选择弹窗 -->
    <el-dialog v-if="sceneConfig.visible" :visible.sync="sceneConfig.visible" width="980px" custom-class="scene-dialog"
               :before-close="sceneBeforeClose">
      <div class="scene_box">
        <div class="left">
          <template v-if="$store.getters.lang === 'en'">
            <h3>{{ guideDescConfig.scene.welcome_tips }}&nbsp;{{ guideDescConfig.scene.welcome_tips2 }}</h3>
          </template>
          <template v-else>
            <h3>{{ guideDescConfig.scene.welcome_tips }}</h3>
            <h3>{{ guideDescConfig.scene.welcome_tips2 }}</h3>
          </template>
          <p>{{ guideDescConfig.scene.trial_plan_tips }}</p>
          <div class="select_box">
            <div class="title">
              {{ guideDescConfig.scene.select_tips }}<span>({{ guideDescConfig.scene.select_single }})</span>
            </div>
            <el-radio-group v-model="sceneConfig.checked">
              <el-popover popper-class="scene_option_popper" placement="bottom-start" trigger="hover" :content="guideDescConfig.scene['tips_' + opt.value]" v-for="opt in sceneConfig.list" :key="opt.value">
                <el-radio-button slot="reference" :label="opt.value">{{ opt.description }}</el-radio-button>
              </el-popover>
            </el-radio-group>
          </div>
          <el-button v-if="sceneConfig.checked" class="cfm_btn" type="primary" size="medium" @click="sceneConfirm">{{ $t('common.confirm') }}</el-button>
        </div>
        <div class="right"></div>
      </div>
    </el-dialog>
    <!-- 步骤概览 -->
    <el-dialog v-if="showStepOverview" :visible.sync="showStepOverview" width="975px" custom-class="step-dialog" :before-close="stepOverviewBeforeClose">
      <div class="step_header">
        <template v-for="s in stepOverviewList">
          <div :class="{ active: s.key === currentOverviewStep }" :key="s.key"
               @click="changeStep(s)">{{ s.label }}
          </div>
        </template>
      </div>
      <div class="step_body">
        <div class="left">
          <img :src="stepOverviewContent[currentOverviewStep].img" alt="" />
        </div>
        <div class="right">
          <div class="title" v-if="$store.getters.lang === 'en'">
            <div>{{ stepOverviewContent[currentOverviewStep].title[0] }}</div>
            <div>{{ stepOverviewContent[currentOverviewStep].title[1] }}</div>
          </div>
          <div class="title" v-else>{{ stepOverviewContent[currentOverviewStep].title[0] + stepOverviewContent[currentOverviewStep].title[1] }}</div>
          <div class="content">
            <template v-if="currentOverviewStep === 'download_app'">
              <div class="img_box">
                <div class="img_item">
                  <img src="@/assets/guide/android.png" alt="" />
                  <div>{{ guideDescConfig.step.download_app_android }}</div>
                </div>
                <div class="img_item">
                  <img src="@/assets/guide/ios.png" alt="" />
                  <div>{{ guideDescConfig.step.download_app_ios }}</div>
                </div>
                <div class="img_item">
                  <img src="@/assets/guide/google.png" alt="" />
                  <div>{{ guideDescConfig.step.download_app_google }}</div>
                </div>
              </div>
              <p class="note">{{ guideDescConfig.step.download_app_note }}</p>
            </template>
            <template v-else>
              <p v-for="(c, i) in stepOverviewContent[currentOverviewStep].content" :key="i">{{ c }}</p>
            </template>
          </div>
          <div class="footer">
            <el-select v-if="currentOverviewStep !== 'download_app'" v-model="sceneConfig.checked" placeholder="" :title="getCurrentIndustryDesc" popper-class="scene-select-popper">
              <template #prefix>
                <span>{{ guideDescConfig.scene.industry }}</span>
              </template>
              <el-option v-for="opt in sceneConfig.list" :key="opt.value" :label="opt.description" :value="opt.value"></el-option>
            </el-select>
            <el-button style="margin-left: auto" v-if="currentOverviewStep === 'download_app'"
                       type="primary"
                       @click="stepConfirm"
                       :loading="btnLoading">
              {{ guideDescConfig.step.appGuide }}
            </el-button>
            <template v-else>
              <el-button style="margin-left: auto" v-if="sceneConfig.checked" type="primary" @click="stepConfirm"
                         :loading="btnLoading">
                {{ guideDescConfig.step.startCreate }}
              </el-button>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import index from './index'
export default index
</script>
<style lang="scss">
@import '@/common/driver/driver-1.0.1.min.css';
@import './index.scss';
</style>
